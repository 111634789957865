export interface Navigation {
    width: number;
    offset: number;
    padding: {
        horizontal: number;
    },
    header: {
        bottom: number;
    };
}

const navigation: Navigation = {
    width: 360,
    offset: 32,
    padding: {
        horizontal: 24
    },
    header: {
        bottom: 8
    }
};

export default navigation;
