export enum Claim {
    UserId = 'Api.DigitalToolbox.World.userId',
    UserName = 'Api.DigitalToolbox.World.userName',
    Roles = 'Api.DigitalToolbox.World.role',
    Rights = 'Api.DigitalToolbox.World.right',
    FirstName = 'Api.DigitalToolbox.World.firstName',
    Insertion = 'Api.DigitalToolbox.World.insertion',
    LastName = 'Api.DigitalToolbox.World.lastName',
    Avatar = 'Api.DigitalToolbox.World.avatar',
    SupplierId = 'Api.DigitalToolbox.World.supplierId',
    SupplierLogo = 'Api.DigitalToolbox.World.supplierLogo',
    CustomerId = 'Api.DigitalToolbox.World.customerId',
    CustomerName = 'Api.DigitalToolbox.World.customerName'
}
