export enum OrderStatus {
    AssessmentInProgress = 0,
    ValidationInProgress = 1,
    ValidationFailed = 2,
    PurchasePlanInProgress = 3,
    TenderPublished = 4,
    OrderInProgress = 5,
    WaitingForCompleting = 6,
    Completed = 7
}
