import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { rgba } from 'polished';

export const Wrapper = styled.div<{ visible?: boolean }>`
    position: fixed;
    bottom: 3rem;
    left: 3rem;
    z-index: 110;
    padding: 1rem;
    line-height: 1;
    white-space: pre;
    box-shadow: 0px 1px 2.5rem 0px ${({ theme }) => rgba(theme.colors.black, 0.1)};
    background-color: ${({ theme }) => theme.colors.white};
    user-select: none;
    transform: translateX(-1.5rem);
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: all .15s ease 0s;
    cursor: pointer;
    ${({ visible }) =>
        visible &&
        css`
        transform: translateX(0);
        pointer-events: auto;
        opacity: 1;
        visibility: visible;
        transition-duration: .35s;
    `}
`;

export const Inner = styled.div`
    display: flex;
    align-items: center;
    opacity: .7;
    font-size: .8rem;
    .icon-wrapper {
        margin-right: .5rem;
    }
`;
