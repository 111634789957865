import { FC } from 'react';
import { Wrapper } from './styles';
import LoadingImage from 'static/loading.svg';

export interface LoadingProps {
    visible?: boolean;
}

const Loading: FC<LoadingProps> = ({ visible = true }) => {
    return (
        <Wrapper visible={visible}>
            <img alt="Loading" src={LoadingImage} />
        </Wrapper>
    );
};

export default Loading;
