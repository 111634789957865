import { StrictMode } from 'react';
import theme from './theme';
import { Global, css, ThemeProvider } from '@emotion/react';
import { ScreenClassProvider, setConfiguration } from 'react-grid-system';
import grid from 'theme/grid';
import colors from 'theme/colors';
import Routes from './Routes';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SessionProvider } from 'contexts/sessionContext';
import 'i18n';
import { ToastProvider } from 'contexts/toastContext';
import Toast from 'components/atoms/Toast';
import arrowDownIcon from 'static/arrow-down-white.svg';
import arrowUpIcon from 'static/arrow-up-white.svg';
import sortIcon from 'static/sort-white.svg';

setConfiguration(grid);

const styles = css`
    ::selection {
        background: ${colors.dark};
        color: ${colors.white};
    }

    *, *:before, *:after {
        box-sizing: border-box;
    }

    html {
        overflow-x: hidden;
    }

    body {
        margin: 0;
        background-color: ${colors.light};
        color: ${colors.dark};
        font-family: 'DM Sans', Helvetica, Arial, sans-serif;
        font-size: 16px;
        line-height: 1.6;
    }

    input, textarea {
        box-shadow: none;
        text-shadow: none;
    }

    table {
        border: 0;
        border-collapse: collapse;
        width: 100%;
        th, td {
            border: 0;
        }
    }

    button {
        cursor: pointer;
        outline: 0;
        border: 0;
        padding: 0;
        line-height: 1;
        background: none;
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        text-shadow: none;
    }

    p {
        margin-top: 0;
        margin-bottom: 1rem;
        a {
            text-decoration: underline;
        }
    }

    .form_select {
        position: relative;
        z-index: 2;
        width: 100%;
    }

    img {
        vertical-align: middle;
    }

    ul, p {
        margin-top: 0;
    }

    .icon-wrapper {
        pointer-events: none;

        span {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        svg {
            vertical-align: middle;
            * {
                fill: inherit;
            }
        }
    }

    a {
        text-decoration: none;
        color: inherit;
        cursor: pointer;
    }

    .mono {
        font-family: 'Roboto Mono', monospace;
    }

    h1, h2, h3, h4 {
        margin-top: 0;
        font-weight: 700;
        font-family: 'Roboto Mono', monospace;
        margin-bottom: 0;
        line-height: 1.2;
    }

    h1 {
        font-size: 2.5rem;
        letter-spacing: -1px;
    }

    h2 {
        font-size: 2.1875rem;
    }

    h3 {
        font-size: 1.625rem;
    }

    h4 {
        font-size: 1rem;
    }

    h5, h6 {
        margin-top: 0;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.2;
        margin-bottom: 0;
    }

    @keyframes rotate {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @keyframes zoomInOut {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }

    table {
        th {
            button {
                position: relative;
                font-weight: 700;
                font-family: 'Roboto Mono', monospace;
                padding-left: 1rem !important;

                &:before {
                    content: '';
                    display: block;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    position: absolute;
                    background-repeat: no-repeat;
                    background-position: left center;
                    background-size: contain;
                    width: .75rem;
                    height: .75rem;
                    background-image: url(${sortIcon});
                }
            }
            &.asc {
                button {
                    &:before {
                        width: .6rem;
                        height: .6rem;
                        background-image: url(${arrowUpIcon});
                    }
                }
            }
            &.desc {
                button {
                    &:before {
                        width: .6rem;
                        height: .6rem;
                        background-image: url(${arrowDownIcon});
                    }
                }
            }
        }
    }

    .slider {
        padding: 1rem 0;
    }

    .slider_track {
        background-color: ${colors.medium};
        height: 2px;
    }

    .slider_track-0 {
        background-color: ${colors.dark};
    }

    .slider_thumb {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: ${colors.dark};
        border: 3px solid ${colors.medium};
        color: ${colors.white};
        transform: translate(-50%, -50%);
        cursor: move;
        &:active {
            transform: translate(-50%, -50%) scale(.95);
        }
        .tooltip_wrapper {
            padding: .65rem;
        }
        .tooltip_label {
            top: 130%;
        }
    }

    .slick-dots {
        bottom: 1rem !important;
        z-index: 1;
        li {
            margin: 0 !important;
            width: 10px !important;
            button {
                width: 10px !important;
                padding-left: 0 !important;
                padding-right: 0 !important;

                &:before {
                    color: ${colors.white} !important;
                    opacity: .5 !important;
                    width: 10px !important;
                }
            }
            &.slick-active {
                button {
                    width: 10px !important;
                    &:before {
                        opacity: 1 !important;
                    }
                }
            }
        }
    }
`;

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false
        }
    }
});

const App = () => {
    return (
        <StrictMode>
            <ScreenClassProvider>
                <Global styles={styles} />
                <ThemeProvider theme={theme}>
                    <SessionProvider>
                        <ToastProvider>
                            <QueryClientProvider client={queryClient}>
                                <Routes />
                            </QueryClientProvider>
                            <Toast />
                        </ToastProvider>
                    </SessionProvider>
                </ThemeProvider>
            </ScreenClassProvider>
        </StrictMode>
    );
};

export default App;
