
import { ToastType } from 'components/atoms/Toast';
import React, { FC, useEffect, useState } from 'react';


const ToastContext = React.createContext({
    isVisible: false,
    message: '',
    type: 'positive',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    showToast: (message: string, type: ToastType) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    hideToast: () => { }
});

interface ToastProviderProps { }

export const ToastProvider: FC<ToastProviderProps> = (props) => {

    const [isVisible, setIsVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [type, setType] = useState<ToastType>('positive');

    const showToast = (message: string, type: ToastType) => {
        setMessage(message);
        setType(type);
        setIsVisible(true);
    };

    const hideToast = () => {
        setIsVisible(false);
    };

    useEffect(() => {
        if (isVisible) {
            setTimeout(hideToast, 3000);
        }
    }, [isVisible]);

    const context = React.useMemo(() => ({
        isVisible,
        message,
        type,
        showToast,
        hideToast
    }), [isVisible, message, type, showToast, hideToast]);

    return (
        <ToastContext.Provider value={context} {...props} />
    );
};

export const useToast = () => {
    const context = React.useContext(ToastContext);
    if (!context) {
        throw new Error('useToast must be used within a ToastProvider');
    }

    return context;
};
