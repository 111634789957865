import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// en files
import commonEn from './common/en.json';
import requestNewPasswordEn from 'components/atoms/form/RequestNewPassword/translations/en.json';
import adminSupplierComponentEn from 'components/organisms/AdminSupplierComponent/translations/en.json';
import assessmentComponentEn from 'components/organisms/Assessment/translations/en.json';
import AdminCustomerOrderEn from 'components/organisms/AdminCustomerOrder/translations/en.json';
import ReviewModuleEn from 'components/organisms/ReviewModule/translations/en.json';
import TimelineEn from 'components/molecules/Timeline/translations/en.json';

// account en files
import logInEn from 'components/pages/account/Login/translations/en.json';
import registerAdminEn from 'components/pages/account/Register/admin/translations/en.json';
import registerSupplierUserEn from 'components/pages/account/Register/supplieruser/translations/en.json';
import registerCustomerUserEn from 'components/pages/account/Register/customeruser/translations/en.json';
import forgotPasswordEn from 'components/pages/account/ForgotPassword/translations/en.json';
import resetPasswordEn from 'components/pages/account/ResetPassword/translations/en.json';

// admin en files
import adminMenuEn from 'components/templates/Admin/translations/en.json';
import adminDashboardEn from 'components/pages/admin/Dashboard/translations/en.json';
import adminUsersEn from 'components/pages/admin/Users/translations/en.json';
import adminUserEditEn from 'components/pages/admin/UserEdit/translations/en.json';
import adminUserDetailEn from 'components/pages/admin/UserDetail/translations/en.json';
import adminSupplierOverviewEn from 'components/pages/admin/SuppliersOverview/translations/en.json';
import adminSupplierAddEn from 'components/pages/admin/SupplierAdd/translations/en.json';
import adminSupplierEditEn from 'components/pages/admin/SupplierEdit/translations/en.json';
import adminSupplierUserEditEn from 'components/pages/admin/SupplierUserEdit/translations/en.json';
import adminSupplierDetailEn from 'components/pages/admin/SupplierDetail/translations/en.json';
import adminCategoriesOverviewEn from 'components/pages/admin/CategoryOverview/translations/en.json';
import adminCategoryComponentsEn from 'components/pages/admin/CategoryComponents/translations/en.json';
import adminCategoryComponentDetailEn from 'components/pages/admin/ComponentDetail/translations/en.json';
import adminAssessmentsOverviewEn from 'components/pages/admin/AssessmentsOverview/translations/en.json';
import adminAssessmentEditorEn from 'components/pages/admin/AssessmentEditor/translations/en.json';
import adminSolutionOverviewEn from 'components/pages/admin/SolutionsOverview/translations/en.json';
import adminCustomersOverviewEn from 'components/pages/admin/CustomersOverview/translations/en.json';
import adminCustomerAddEn from 'components/pages/admin/CustomerAdd/translations/en.json';
import adminCustomerEditEn from 'components/pages/admin/CustomerEdit/translations/en.json';
import adminCustomerOrdersEn from 'components/pages/admin/CustomerOrders/translations/en.json';
import adminCustomerOrderEn from 'components/organisms/AdminCustomerOrder/translations/en.json';
import adminCustomerOrderOverviewEn from 'components/pages/admin/CustomerOrderDetail/translations/en.json';
import adminCustomerUsersEn from 'components/pages/admin/CustomerUsers/translations/en.json';
import adminCustomerUserEditEn from 'components/pages/admin/CustomerUserEdit/translations/en.json';
import adminFaqsOverviewEn from 'components/pages/admin/FaqsOverview/translations/en.json';
import adminSolutionFunctionsEn from 'components/pages/admin/SolutionFunctions/translations/en.json';
import adminSupplierReviewModuleEn from 'components/pages/admin/SupplierReviewModule/translations/en.json';
import adminCustomerReviewModuleEn from 'components/pages/admin/CustomerReviewModule/translations/en.json';
import adminResourceDocumentsOverviewEn from 'components/pages/admin/ResourceDocumentsOverview/translations/en.json';
import adminSupplierProductDetailEn from 'components/pages/admin/SupplierProductDetail/translations/en.json';

// supplier en files
import supplierMenuEn from 'components/templates/Supplier/translations/en.json';
import supplierDashboardEn from 'components/pages/supplier/Dashboard/translations/en.json';
import supplierUserProfileEn from 'components/pages/supplier/UserProfile/translations/en.json';
import supplierUsersEn from 'components/pages/supplier/UserRights/translations/en.json';
import supplierCompanyProfileEn from 'components/pages/supplier/CompanyProfile/translation/en.json';
import supplierComponentsOverviewEn from 'components/pages/supplier/ComponentsOverview/translations/en.json';
import supplierComponentsAddCategoryEn from 'components/pages/supplier/ComponentsAddCategory/translations/en.json';
import supplierComponentsAddEn from 'components/pages/supplier/ComponentsAdd/translations/en.json';
import supplierComponentAddDetailEn from 'components/pages/supplier/ComponentsAddDetail/translations/en.json';
import supplierAssessmentStartEn from 'components/pages/supplier/AssessmentStart/translations/en.json';
import supplierAssessmentEn from 'components/pages/supplier/Assessment/translations/en.json';
import supplierComponentDetailEn from 'components/pages/supplier/ComponentDetail/translations/en.json';
import supplierLeadsEn from 'components/pages/supplier/Leads/translations/en.json';
import supplierCustomerOrderDetailsEn from 'components/pages/supplier/CustomerOrderDetails/translations/en.json';

// customer en files
import customerMenuEn from 'components/templates/Customer/translations/en.json';
import customerAssessmentEn from 'components/pages/customer/Assessment/translations/en.json';
import customerAssessmentStartEn from 'components/pages/customer/AssessmentStart/translations/en.json';
import customerDashboardEn from 'components/pages/customer/Dashboard/translations/en.json';
import customerSolutionAddEn from 'components/pages/customer/SolutionAdd/translations/en.json';
import customerSolutionChooseFunctionEn from 'components/pages/customer/SolutionChooseFunction/translations/en.json';
import customerSolutionsOverviewEn from 'components/pages/customer/SolutionsOverview/translations/en.json';
import customerSolutionStartEn from 'components/pages/customer/SolutionStart/translations/en.json';
import customerUserRightsEn from 'components/pages/customer/UserRights/translations/en.json';
import customerUserProfileEn from 'components/pages/customer/UserProfile/translations/en.json';
import customerOrderDetailEn from 'components/pages/customer/OrderDetail/translations/en.json';
import customerComponentOverviewEn from 'components/pages/customer/ComponentOverview/translations/en.json';
import customerComponentDetailEn from 'components/pages/customer/ComponentDetail/translations/en.json';
import customerOrderOverviewEn from 'components/pages/customer/OrderOverview/translations/en.json';

const resources = {
    en: {
        common: commonEn,
        requestNewPassword: requestNewPasswordEn,
        adminSupplierComponent: adminSupplierComponentEn,
        assessmentComponent: assessmentComponentEn,
        AdminCustomerOrder: AdminCustomerOrderEn,
        reviewModule: ReviewModuleEn,
        timeline: TimelineEn,

        // account
        login: logInEn,
        registerAdmin: registerAdminEn,
        registerSupplierUser: registerSupplierUserEn,
        registerCustomerUser: registerCustomerUserEn,
        forgotPassword: forgotPasswordEn,
        resetPassword: resetPasswordEn,

        // admin
        adminMenu: adminMenuEn,
        adminDashboard: adminDashboardEn,
        adminUsers: adminUsersEn,
        adminUserEdit: adminUserEditEn,
        adminUserDetail: adminUserDetailEn,
        adminSupplierOverview: adminSupplierOverviewEn,
        adminSupplierAdd: adminSupplierAddEn,
        adminSupplierEdit: adminSupplierEditEn,
        adminSupplierUserEdit: adminSupplierUserEditEn,
        adminSupplierDetail: adminSupplierDetailEn,
        adminCategoriesOverview: adminCategoriesOverviewEn,
        adminCategoryComponents: adminCategoryComponentsEn,
        adminCategoryComponentDetail: adminCategoryComponentDetailEn,
        adminAssessmentsOverview: adminAssessmentsOverviewEn,
        adminAssessmentEditor: adminAssessmentEditorEn,
        adminCustomersOverview: adminCustomersOverviewEn,
        adminCustomerAdd: adminCustomerAddEn,
        adminSolutionOverview: adminSolutionOverviewEn,
        adminCustomerEdit: adminCustomerEditEn,
        adminCustomerOrders: adminCustomerOrdersEn,
        adminCustomerOrder: adminCustomerOrderEn,
        adminCustomerOrderOverview: adminCustomerOrderOverviewEn,
        adminCustomerUsers: adminCustomerUsersEn,
        adminCustomerUserEdit: adminCustomerUserEditEn,
        adminFaqsOverview: adminFaqsOverviewEn,
        adminSolutionFunctions: adminSolutionFunctionsEn,
        adminSupplierReviewModule: adminSupplierReviewModuleEn,
        adminCustomerReviewModule: adminCustomerReviewModuleEn,
        adminResourceDocumentsOverviewEn: adminResourceDocumentsOverviewEn,
        adminSupplierProductDetailEn: adminSupplierProductDetailEn,

        // supplier
        supplierMenu: supplierMenuEn,
        supplierDashboard: supplierDashboardEn,
        supplierUserProfile: supplierUserProfileEn,
        supplierUsers: supplierUsersEn,
        supplierCompanyProfile: supplierCompanyProfileEn,
        supplierComponentsOverview: supplierComponentsOverviewEn,
        supplierComponentsAddCategory: supplierComponentsAddCategoryEn,
        supplierComponentsAdd: supplierComponentsAddEn,
        supplierComponentAddDetail: supplierComponentAddDetailEn,
        supplierAssessmentStart: supplierAssessmentStartEn,
        supplierAssessment: supplierAssessmentEn,
        supplierComponentDetail: supplierComponentDetailEn,
        supplierLeads: supplierLeadsEn,
        supplierCustomerOrderDetails: supplierCustomerOrderDetailsEn,

        // customer
        customerMenu: customerMenuEn,
        customerDashboard: customerDashboardEn,
        customerUserRights: customerUserRightsEn,
        customerUserProfile: customerUserProfileEn,
        customerSolutionsOverview: customerSolutionsOverviewEn,
        customerSolutionAdd: customerSolutionAddEn,
        customerAssessment: customerAssessmentEn,
        customerAssessmentStart: customerAssessmentStartEn,
        customerSolutionChooseFunction: customerSolutionChooseFunctionEn,
        customerSolutionStart: customerSolutionStartEn,
        customerOrderDetail: customerOrderDetailEn,
        customerComponentOverview: customerComponentOverviewEn,
        customerComponentDetail: customerComponentDetailEn,
        customerOrderOverview: customerOrderOverviewEn
    }
};

const ns = Object.keys(resources.en);

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: 'en',
        fallbackLng: 'en',
        supportedLngs: ['en'],
        debug: process.env.NODE_ENV === 'development',
        ns,
        defaultNS: 'common',

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
