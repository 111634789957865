import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { rgba } from 'polished';

export const Wrapper = styled.div<{ visible?: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 111;
    background-color: ${({ theme }) => rgba(theme.colors.white, .75)};
    user-select: none;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: all .15s ease 0s;

    img {
        width: 4rem;
        height: auto;
        transform-origin: center center;
        animation: zoomInOut 1s ease-in-out infinite;
    }

    ${({ visible }) =>
        visible &&
        css`
        pointer-events: auto;
        opacity: 1;
        visibility: visible;
        transition-duration: .35s;
    `}
`;