export interface Colors {
    primary: string;
    secondary: string;
    success: string;
    error: string;
    dark: string;
    white: string;
    black: string;
    light: string;
    medium: string;
    border: string;
    transparent: string;
}

const colors: Colors = {
    primary: '#0B93FF',
    secondary: '#EB9532',
    success: '#12BF63',
    error: '#F03745',
    dark: '#34173D',
    white: '#ffffff',
    black: '#000000',
    light: '#F4F4FA',
    medium: '#DCD8E3',
    border: '#AEA2B1',
    transparent: 'transparent'
};

export default colors;
