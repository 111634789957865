import colors from './colors';
import navigation from './navigation';
import header from './header';
import greys from './greys';

const theme = {
    colors,
    greys,
    navigation,
    header
};

export default theme;
