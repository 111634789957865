import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import colors from 'theme/colors';
import classnames from 'classnames';

interface IconProps {
    size?: number;
    color?: string;
    name: string;
    className?: string;
}

const Icon: FC<IconProps> = ({ size = 1.5, color = colors.dark, name, className }) => {
    return (
        <ReactSVG
            wrapper="span"
            className={classnames('icon-wrapper', className)}
            beforeInjection={(svg) => {
                svg.setAttribute('style', `width: ${size}rem; height: ${size}rem;`);
                color && svg.setAttribute('fill', color);
            }}
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            src={require(`static/icons/${name}.svg`).default}
        />
    );
};

export default Icon;
