import { FC } from 'react';
import { Wrapper, Inner } from './styles';
import Icon from 'components/atoms/Icon';
import { useTheme } from '@emotion/react';
import { useToast } from 'contexts/toastContext';

export type ToastType = 'positive' | 'negative';

export interface ToastProps { }

const Toast: FC<ToastProps> = () => {
    const theme = useTheme();
    const { isVisible, message, type, hideToast } = useToast();

    let iconName = '';

    switch (type) {
        case 'positive':
            iconName = 'check';
            break;
        case 'negative':
            iconName = 'cross';
            break;
        default:
            iconName = 'check';
            break;
    }

    return (
        <Wrapper visible={isVisible} onClick={hideToast}>
            <Inner>
                <Icon name={iconName} size={.85} color={theme.colors.dark} />
                {message}
            </Inner>
        </Wrapper>
    );
};

export default Toast;
