import { lazy, Suspense } from 'react';
import { Router } from '@reach/router';
import Loading from 'components/atoms/Loading';

// Templates
const Account = lazy(() => import('components/templates/Account'));
const Admin = lazy(() => import('components/templates/Admin'));
const Supplier = lazy(() => import('components/templates/Supplier'));
const Default = lazy(() => import('components/templates/Default'));
const Start = lazy(() => import('components/templates/Start'));
const Customer = lazy(() => import('components/templates/Customer'));

// Pages
const Login = lazy(() => import('components/pages/account/Login'));
const LoginWithUsername = lazy(() => import('components/pages/account/LoginWithUsername'));
const ForgotPassword = lazy(() => import('components/pages/account/ForgotPassword'));
const RegisterAdmin = lazy(() => import('components/pages/account/Register/admin'));
const RegisterSupplierUser = lazy(() => import('components/pages/account/Register/supplieruser'));
const RegisterCustomerUser = lazy(() => import('components/pages/account/Register/customeruser'));
const ResetPassword = lazy(() => import('components/pages/account/ResetPassword'));

const AdminDashboard = lazy(() => import('components/pages/admin/Dashboard'));
const AdminUsers = lazy(() => import('components/pages/admin/Users'));
const AdminUserEdit = lazy(() => import('components/pages/admin/UserEdit'));
const AdminUserDetail = lazy(() => import('components/pages/admin/UserDetail'));
const AdminCategoryOverview = lazy(() => import('components/pages/admin/CategoryOverview'));
const AdminCategoryComponents = lazy(() => import('components/pages/admin/CategoryComponents'));
const AdminComponentDetail = lazy(() => import('components/pages/admin/ComponentDetail'));
const AdminSuppliersOverview = lazy(() => import('components/pages/admin/SuppliersOverview'));
const AdminSupplierAdd = lazy(() => import('components/pages/admin/SupplierAdd'));
const AdminSupplierEdit = lazy(() => import('components/pages/admin/SupplierEdit'));
const AdminSupplierDetail = lazy(() => import('components/pages/admin/SupplierDetail'));
const AdminSupplierUserEdit = lazy(() => import('components/pages/admin/SupplierUserEdit'));
const AdminAssessmentsOverview = lazy(() => import('components/pages/admin/AssessmentsOverview'));
const AdminAssessmentEditor = lazy(() => import('components/pages/admin/AssessmentEditor'));
const AdminClientsOverview = lazy(() => import('components/pages/admin/ClientsOverview'));
const AdminClientDetail = lazy(() => import('components/pages/admin/ClientDetail'));
const AdminSolutionsOverview = lazy(() => import('components/pages/admin/SolutionsOverview'));
const AdminSolutionDetail = lazy(() => import('components/pages/admin/SolutionDetail'));
const AdminCustomersOverview = lazy(() => import('components/pages/admin/CustomersOverview'));
const AdminCustomerAdd = lazy(() => import('components/pages/admin/CustomerAdd'));
const AdminCustomerEdit = lazy(() => import('components/pages/admin/CustomerEdit'));
const AdminCustomerOrders = lazy(() => import('components/pages/admin/CustomerOrders'));
const AdminCustomerOrderDetail = lazy(() => import('components/pages/admin/CustomerOrderDetail'));
const AdminCustomerUsers = lazy(() => import('components/pages/admin/CustomerUsers'));
const AdminCustomerUserEdit = lazy(() => import('components/pages/admin/CustomerUserEdit'));
const AdminFaqsOverview = lazy(() => import('components/pages/admin/FaqsOverview'));
const AdminSolutionFunctions = lazy(() => import('components/pages/admin/SolutionFunctions'));
const AdminSupplierReviewModule = lazy(() => import('components/pages/admin/SupplierReviewModule'));
const AdminCustomerReviewModule = lazy(() => import('components/pages/admin/CustomerReviewModule'));
const AdminResourceDocumentsOverview = lazy(() => import('components/pages/admin/ResourceDocumentsOverview'));
const AdminSupplierProductDetail = lazy(() => import('components/pages/admin/SupplierProductDetail'));

const SupplierDashboard = lazy(() => import('components/pages/supplier/Dashboard'));
const SupplierComponentsOverview = lazy(() => import('components/pages/supplier/ComponentsOverview'));
const SupplierComponentDetail = lazy(() => import('components/pages/supplier/ComponentDetail'));
const SupplierComponentsAddCategory = lazy(() => import('components/pages/supplier/ComponentsAddCategory'));
const SupplierComponentsAdd = lazy(() => import('components/pages/supplier/ComponentsAdd'));
const SupplierComponentsAddDetail = lazy(() => import('components/pages/supplier/ComponentsAddDetail'));
const SupplierUserRights = lazy(() => import('components/pages/supplier/UserRights'));
const SupplierUserProfile = lazy(() => import('components/pages/supplier/UserProfile'));
const SupplierCompanyProfile = lazy(() => import('components/pages/supplier/CompanyProfile'));
const SupplierAssessmentChapter = lazy(() => import('components/pages/supplier/AssessmentChapter'));
const SupplierAssessment = lazy(() => import('components/pages/supplier/Assessment'));
const SupplierAssessmentStart = lazy(() => import('components/pages/supplier/AssessmentStart'));
const SupplierFinance = lazy(() => import('components/pages/supplier/Finance'));
const SupplierFaqsOverview = lazy(() => import('components/pages/supplier/Faqs'));
const SupplierLeads = lazy(() => import('components/pages/supplier/Leads'));
const SupplierCustomerOrderDetails = lazy(() => import('components/pages/supplier/CustomerOrderDetails'));

const CustomerDashboard = lazy(() => import('components/pages/customer/Dashboard'));
const CustomerUserRights = lazy(() => import('components/pages/customer/UserRights'));
const CustomerUserProfile = lazy(() => import('components/pages/customer/UserProfile'));
const CustomerSolutionsOverview = lazy(() => import('components/pages/customer/SolutionsOverview'));
const CustomerSolutionAdd = lazy(() => import('components/pages/customer/SolutionAdd'));
const CustomerSolutionChooseFunction = lazy(() => import('components/pages/customer/SolutionChooseFunction'));
const CustomerSolutionStart = lazy(() => import('components/pages/customer/SolutionStart'));
const CustomerAssessmentStart = lazy(() => import('components/pages/customer/AssessmentStart'));
const CustomerAssessment = lazy(() => import('components/pages/customer/Assessment'));
const CustomerOrderDetail = lazy(() => import('components/pages/customer/OrderDetail'));
const CustomerComponentOverview = lazy(() => import('components/pages/customer/ComponentOverview'));
const CustomerComponentDetail = lazy(() => import('components/pages/customer/ComponentDetail'));
const CustomerOrderOverview = lazy(() => import('components/pages/customer/OrderOverview'));
const CustomerFaqsOverview = lazy(() => import('components/pages/customer/Faqs'));

const Routes = () => {
    return (
        <Suspense fallback={<Loading />}>
            <Router>
                <Account path="account">
                    <Login path="login" />
                    <Login path="login/:redirectUrl" />
                    {
                        process.env.NODE_ENV === 'development' &&
                        <LoginWithUsername path="loginwithusername/:username" />
                    }
                    <ForgotPassword path="forgot-password" />
                    <RegisterAdmin path="register/admin/:code" />
                    <RegisterSupplierUser path="register/supplier/:code" />
                    <RegisterCustomerUser path="register/customer/:code" />
                    <ResetPassword path="reset-password" />
                </Account>
                <Default path="/" default />
                <Admin path="admin">
                    <AdminDashboard default />
                    <AdminUsers path="users" />
                    <AdminUserEdit path="users/edit/:id" />
                    <AdminUserDetail path="profile" />
                    <AdminCategoryOverview path="categories" />
                    <AdminCategoryComponents path="categories/:id/components" />
                    <AdminComponentDetail path="categories/:categoryId/components/:id/detail" />
                    <AdminSuppliersOverview path="suppliers" />
                    <AdminSupplierAdd path="suppliers/add" />
                    <AdminSupplierEdit path="suppliers/:id/edit" />
                    <AdminSupplierUserEdit path="suppliers/:id/users/:userId/edit" />
                    <AdminSupplierDetail path="suppliers/:id" />
                    <AdminAssessmentsOverview path="assessments" />
                    <AdminAssessmentEditor path="assessments/:id/edit" />
                    <AdminClientsOverview path="clients" />
                    <AdminClientDetail path="clients/detail" />
                    <AdminSolutionsOverview path="solutions" />
                    <AdminSolutionDetail path="solutions/detail" />
                    <AdminCustomersOverview path="customers" />
                    <AdminCustomerAdd path="customers/add" />
                    <AdminCustomerEdit path="customers/:id/edit" />
                    <AdminCustomerOrders path="customers/:id/orders" />
                    <AdminCustomerOrderDetail path="customers/:customerId/orders/:orderId/detail" />
                    <AdminCustomerUsers path="customers/:id/users" />
                    <AdminCustomerUserEdit path="customers/:id/users/:userId/edit" />
                    <AdminResourceDocumentsOverview path="resource-documents" />
                    <AdminFaqsOverview path="faq" />
                    <AdminSupplierReviewModule path="supplierproduct/:supplierProductId/review" />
                    <AdminCustomerReviewModule path="customerorder/:customerOrderId/review" />
                    <AdminSolutionFunctions path="solutions/:id/functions" />
                    <AdminSupplierProductDetail path="supplier/product/:id/detail" />
                </Admin>
                <Supplier path="supplier">
                    <SupplierDashboard default />
                    <SupplierComponentsOverview path="components" />
                    <SupplierComponentDetail path="components/:id/detail" />
                    <SupplierComponentsAddCategory path="components/addcategory" />
                    <SupplierComponentsAdd path="components/addcategory/:categoryId/addcomponent" />
                    <SupplierComponentsAddDetail path="components/addcategory/:categoryId/addcomponent/:componentId/detail" />
                    <SupplierUserRights path="users" />
                    <SupplierUserProfile path="profile" />
                    <SupplierCompanyProfile path="company" />
                    <SupplierAssessmentStart path="assessment/start/:supplierProductId" />
                    <SupplierAssessment path="assessment/:supplierProductId" />
                    <SupplierFinance path="finance" />
                    <SupplierFaqsOverview path="faq" />
                    <SupplierLeads path="leads" />
                    <SupplierCustomerOrderDetails path="order/:orderId/details" />
                </Supplier>
                <Customer path="customer">
                    <CustomerDashboard default />
                    <CustomerUserRights path="users" />
                    <CustomerUserProfile path="profile" />
                    <CustomerSolutionsOverview path="solutions" />
                    <CustomerSolutionAdd path="solutions/add" />
                    <CustomerSolutionChooseFunction path="solutions/add/:solutionId/functions" />
                    <CustomerSolutionStart path="solutions/add/:solutionId/functions/:solutionFunctionId/start" />
                    <CustomerAssessmentStart path="assessment/start/:customerOrderId" />
                    <CustomerAssessment path="assessment/:customerOrderId" />
                    <CustomerOrderDetail path="order/:id/detail" />
                    <CustomerComponentOverview path="order/:orderId/component/:componentId/products" />
                    <CustomerComponentDetail path="order/:orderId/component/:componentId/product/:productId/detail" />
                    <CustomerOrderOverview path="order/:id/overview" />
                    <CustomerFaqsOverview path="faq" />
                </Customer>
                <Start path="assessment">
                    <SupplierAssessmentChapter default />
                </Start>
            </Router>
        </Suspense>
    );
};

export default Routes;
